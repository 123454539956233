import globals from 'src/translations/globals';
import {
  formatDecimalToPercentage,
  formatDollarAmount,
  formatNumberToString,
} from '../../../utils/formatters';

// Add globals in the following format:
//
//   CONSTANT_CASE_NAME: String | Number
//
// or,
//
//   CONSTANT_CASE_NAME: {
//     raw: Number,
//     formatter: Function,
//     config: Object,
//   }
//
// or,
//
//   CONSTANT_CASE_NAME: {
//     'en-ca': String,
//     'fr-ca': String,
//   }
//
// `formatter` should accept arguments in the form: formatter(value, { ...configObject })
// `config` is optional; merged with `{ locale }` and passed to `formatter`

export const defaultGlobals = {
  TOTAL_BUSINESS_AUA: {
    'en-ca': '$50+ billion',
    'fr-ca': '+ 50 milliards\u00A0$',
  },
  AUM_THRESHOLD_CORE: {
    raw: 1,
    formatter: formatDollarAmount,
  },
  AUM_THRESHOLD_CORE_ALTS: {
    raw: globals.AUM_THRESHOLD_CORE_ALTS,
    formatter: formatDollarAmount,
  },
  AUM_THRESHOLD_PREMIUM: {
    raw: globals.AUM_THRESHOLD_PREMIUM,
    formatter: formatDollarAmount,
  },
  AUM_THRESHOLD_GENERATION: {
    raw: 500_000,
    formatter: formatDollarAmount,
  },
  CASH_ATM_FEE_REIMBURSEMENT_MAX: {
    raw: 5,
    formatter: formatDollarAmount,
  },
  CASH_CASHBACK_RATE: {
    raw: 0.01,
    formatter: formatDecimalToPercentage,
  },
  CASH_CDIC_COVERAGE: {
    raw: 500_000,
    formatter: formatDollarAmount,
  },
  CASH_INTEREST_RATE_CORE: {
    raw: 0.0325,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 2,
    },
  },
  CASH_INTEREST_RATE_PREMIUM: {
    raw: 0.0375,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 2,
    },
  },
  CASH_INTEREST_RATE_GENERATION: {
    raw: 0.0425,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 2,
    },
  },
  CASH_INTEREST_RATE_DIRECT_DEPOSIT_BONUS: {
    raw: 0.005,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  CASH_DAILY_E_TRANSFER_LIMIT: {
    raw: 5_000,
    formatter: formatDollarAmount,
  },
  CASH_WEEKLY_E_TRANSFER_LIMIT: {
    raw: 10_000,
    formatter: formatDollarAmount,
  },
  CRYPTO_COIN_COUNT_LOWER_BOUND: {
    raw: 60,
    formatter: formatNumberToString,
  },
  CRYPTO_COLD_STORAGE_AMOUNT_PER_PARTNER: {
    'en-ca': '$75M',
    'fr-ca': '75\u00a0millions de dollars',
  },
  CRYPTO_STAKING_YIELD_CORE: {
    raw: 0.12,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  CRYPTO_STAKING_YIELD_PREMIUM: {
    raw: 0.12,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  CRYPTO_STAKING_YIELD_GENERATION: {
    raw: 0.145,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  CRYPTO_TRANSACTION_FEE_MIN: {
    raw: 0.005,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  CRYPTO_TRANSACTION_FEE_MAX: {
    raw: 0.02,
    formatter: formatDecimalToPercentage,
  },
  CRYPTO_TRANSACTION_FEE_CORE: {
    raw: 0.02,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  CRYPTO_TRANSACTION_FEE_PREMIUM: {
    raw: 0.01,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  CRYPTO_TRANSACTION_FEE_GENERATION: {
    raw: 0.005,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  CURRENT_YEAR: new Date().getFullYear(),
  DIY_FX_RATE: {
    raw: 0.015,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  DIY_TRADEABLE_SECURITIES_COUNT: {
    raw: 9000,
    formatter: formatNumberToString,
  },
  DIY_TRADING_COMMISSION_FEE: {
    raw: 0,
    formatter: formatDollarAmount,
  },
  HISP_INTEREST_RATE: {
    raw: 0.0486,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 2,
    },
  },
  INSTANT_DEPOSIT_MIN: {
    raw: 1_500,
    formatter: formatDollarAmount,
  },
  INSTANT_DEPOSIT_MAX_CORE: {
    raw: 50_000,
    formatter: formatDollarAmount,
  },
  INSTANT_DEPOSIT_MAX: {
    raw: 250_000,
    formatter: formatDollarAmount,
  },
  INSTANT_WITHDRAWAL_FEE: {
    raw: 0.025,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  MANAGED_INVEST_FEE_CORE: {
    raw: globals.MANAGED_INVEST_FEE_CORE,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  MANAGED_INVEST_FEE_PREMIUM: {
    raw: globals.MANAGED_INVEST_FEE_PREMIUM,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  MANAGED_INVEST_FEE_GENERATION_MIN: {
    raw: 0.002,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  MANAGED_INVEST_FEE_GENERATION_MAX: {
    raw: 0.004,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  MORTGAGE_RATE_REBATE_CORE: {
    raw: 0.0005,
    formatter: formatDecimalToPercentage,
    config: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  MORTGAGE_RATE_REBATE_PREMIUM: {
    raw: 0.001,
    formatter: formatDecimalToPercentage,
    config: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  MORTGAGE_RATE_REBATE_GENERATION: {
    raw: 0.0015,
    formatter: formatDecimalToPercentage,
    config: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  OPTIONS_CONTRACT_PRICE_CORE: {
    raw: 2,
    formatter: formatDollarAmount,
  },
  OPTIONS_CONTRACT_PRICE_PREMIUM: {
    raw: 0.75,
    formatter: formatDollarAmount,
    config: { maximumFractionDigits: 2 },
  },
  OPTIONS_CONTRACT_PRICE_GENERATION: {
    raw: 0.75,
    formatter: formatDollarAmount,
    config: { maximumFractionDigits: 2 },
  },
  FHSA_LIFETIME_CONTRIBUTION: {
    raw: 40_000,
    formatter: formatDollarAmount,
  },
  FHSA_MAX_CONTRIBUTION_2023: {
    raw: 8_000,
    formatter: formatDollarAmount,
  },
  TFSA_LIFETIME_CONTRIBUTION_LIMIT: {
    raw: 95_000,
    formatter: formatDollarAmount,
  },
  TFSA_CONTRIBUTION_LIMIT_2023: {
    raw: 6_500,
    formatter: formatDollarAmount,
  },
  TFSA_CONTRIBUTION_LIMIT_2024: {
    raw: 7_000,
    formatter: formatDollarAmount,
  },
  RRSP_CONTRIBUTION_LIMIT_2022: {
    raw: 29120,
    formatter: formatDollarAmount,
  },
  RRSP_CONTRIBUTION_LIMIT_2023: {
    raw: 30780,
    formatter: formatDollarAmount,
  },
  RRSP_CONTRIBUTION_LIMIT_2024: {
    raw: 31560,
    formatter: formatDollarAmount,
  },
  TAX_TIER_PRICE_BASIC: {
    raw: 0,
    formatter: formatDollarAmount,
  },
  TAX_TIER_PRICE_PLUS: {
    raw: 40,
    formatter: formatDollarAmount,
  },
  TAX_TIER_PRICE_PRO: {
    raw: 80,
    formatter: formatDollarAmount,
  },
  TRANSFER_MIN_VALUE_FOR_REIMBURSEMENT: {
    raw: globals.TRANSFER_MIN_VALUE_FOR_REIMBURSEMENT,
    formatter: formatDollarAmount,
  },
  TRANSFER_MATCH_BONUS: {
    raw: globals.TRANSFER_MATCH_BONUS,
    formatter: formatDecimalToPercentage,
  },
  SAVE_INTEREST_RATE: {
    raw: 0.015,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  USD_ACCOUNT_COST: {
    raw: 10,
    formatter: formatDollarAmount,
  },
  USD_ACCOUNT_FX_RATE_CORE: {
    raw: 0.015,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 1,
    },
  },
  USD_ACCOUNT_FX_RATE_PREMIUM: {
    raw: 0,
    formatter: formatDecimalToPercentage,
  },
  USD_ACCOUNT_FX_RATE_GENERATION: {
    raw: 0,
    formatter: formatDecimalToPercentage,
  },
  WS_CLIENT_COUNT: {
    'en-ca': '3\u00a0million',
    'fr-ca': '3\u00a0millions',
  },
  ALTS_PRIVATE_CREDIT_YIELD_TARGET: {
    raw: 0.09,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 0,
    },
  },
  ALTS_PRIVATE_CREDIT_AUM: {
    'en-ca': '$200M+',
    'fr-ca': '+\u00a0200\u00a0M$',
  },
  ALTS_PRIVATE_CREDIT_SENIOR_SECURED_FIRST_LIEN_CREDIT: {
    raw: 1.0,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 0,
    },
  },
  ALTS_PRIVATE_CREDIT_WEIGHTED_AVG_SENIOR_LEVERAGE: {
    'en-ca': '4.0',
    'fr-ca': '4,0',
  },
  ALTS_PRIVATE_CREDIT_WEIGHTED_AVG_LOAN_TO_VALUE: {
    raw: 0.41,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 0,
    },
  },
  ALTS_PRIVATE_EQUITY_OUTPERFORMANCE: {
    raw: 0.0475,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 2,
    },
  },
  ALTS_PRIVATE_EQUITY_RATE_OF_RETURN: {
    raw: 0.18,
    formatter: formatDecimalToPercentage,
    config: {
      maximumFractionDigits: 0,
    },
  },
  ALTS_PRIVATE_CREDIT_MIN_INVESTMENT: {
    raw: 10_000,
    formatter: formatDollarAmount,
  },
  ALTS_PRIVATE_EQUITY_MIN_INVESTMENT: {
    raw: 10_000,
    formatter: formatDollarAmount,
  },
  EXAMPLE_GLOBAL_TEXT: {
    'en-ca': 'example global',
    'fr-ca': 'example global',
  },
  EXAMPLE_GLOBAL_NUMBER: {
    raw: 10,
    formatter: formatDollarAmount,
  },
};
